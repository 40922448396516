<template>
  <layout>
    <div class="editor__container">
      <v-select dense solo @input="selectSql" :items="sqlItems"/>
      <div id="editor" style="margin-bottom: 24px"></div>
    </div>
    <v-alert v-if="vError" class="mb-2" type="error">
      {{ vError }}
    </v-alert>
    <div v-if="!isDataSync" class="d-flex align-center mb-3">
      <v-btn
        color="warning"
        dark
        :loading="isLoading"
        @click="run"
      >Run
      </v-btn>
    </div>
    <v-card v-if="results">
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          clearable
        />
        <v-spacer/>
        <div v-if="results" class="pr-6">Total: {{ total }}<span v-if="hasNext">+</span></div>
        <v-btn
          v-if="hasNext"
          :color="btnColor"
          dark
          :loading="isLoadingMore"
          @click="loadMore">
          Load more
        </v-btn>
      </v-card-title>
      <v-data-table
        dense
        class="elevation-1"
        :headers="headers"
        :items="items"
        :options.sync="options"
        :footer-props="defaultFooterProps"
        :search="search"
        :height="450"
      />
    </v-card>
  </layout>
</template>

<script>
import { EditorView, basicSetup } from 'codemirror';
import { sql } from '@codemirror/lang-sql';

let editor;

export default {
  data() {
    return {
      sql: '',
      search: '',
      queryExecutionId: '',
      isDataSync: false,
      isLoading: false,
      isLoadingMore: false,
      results: null,
      options: {
        page: 1,
        itemsPerPage: 100
      },
      footerProps: {
        'items-per-page-options': [50, 100, 500]
      }
    };
  },
  watch: {
    sql() {
      this.isDataSync = false;
    }
  },
  computed: {
    sqlItems() {
      return [{
        text: 'MAU',
        value: `SELECT date_trunc('month', date) as month, count(distinct client_id) as count
FROM v_start
GROUP BY date_trunc('month', date)
ORDER BY date_trunc('month', date) DESC;`
      }, {
        text: 'Samples',
        value: `select count(*), month
from "v_samples"
group by month
order by month asc;`
      }];
    },
    columns() {
      if (!this.results) {
        return [];
      }

      return this.results.ResultSet.ResultSetMetadata.ColumnInfo.map(info => info.Name);
    },
    headers() {
      if (!this.results) {
        return [];
      }

      return this.results.ResultSet.ResultSetMetadata.ColumnInfo.map((info, i) => {
        return {
          text: info.Name,
          value: info.Name + ':' + i
        };
      });
    },
    items() {
      if (!this.results) {
        return [];
      }

      const columns = this.columns;

      const Rows = this.results.ResultSet.Rows;

      return Rows.slice(1).map(r => {
        const row = {};
        columns.forEach((name, i) => {
          row[name + ':' + i] = r.Data[i] ? (r.Data[i].VarCharValue) : undefined;
        });
        return row;
      });
    },
    total() {
      return this.items.length;
    },
    hasNext() {
      return this.results && this.results.NextToken;
    },
    btnColor() {
      if (this.vError) {
        return 'error';
      }

      return 'primary';
    }
  },
  methods: {
    async run() {
      this.cleanError();
      this.queryExecutionId = null;
      this.results = null;
      this.isLoading = true;
      try {
        await this.$store.api.athena.getQueryExecutionId(this.sql).then(res => {
          this.queryExecutionId = res.QueryExecutionId;
        });

        await this.$store.api.athena.awaitExecution(this.queryExecutionId);

        await this.load();

        this.isDataSync = true;
      } catch (err) {
        this.setError(err);
      } finally {
        this.isLoading = false;
      }
    },
    load() {
      this.$store.api.athena.getQueryResults(this.queryExecutionId).then(results => {
        this.results = results;
      });
    },
    loadMore() {
      this.isLoadingMore = true;
      this.$store.api.athena.getQueryResults(this.queryExecutionId, this.results.NextToken).then(results => {
        this.results.ResultSet.Rows = this.results.ResultSet.Rows.concat(results.ResultSet.Rows);
        this.results.NextToken = results.NextToken;
      }).catch(this.setError).then(() => {
        this.isLoadingMore = false;
      });
    },
    selectSql(insert) {
      editor.dispatch({
        changes: {from: 0, to: editor.state.doc.length, insert}
      });
    }
  },
  mounted() {
    editor = new EditorView({
      extensions: [
        basicSetup,
        sql(),
        EditorView.updateListener.of((v) => {
          if (v.docChanged) {
            this.sql = editor.state.doc.toString();
          }
        })
      ],
      parent: document.querySelector('#editor')
    });

    const insert = this.$store.getParam('sql');

    if (insert) {
      editor.dispatch({
        changes: { from: 0, insert}
      });
    }
  }
};
</script>
